// This can be used in pages or components

export const useHeroAuth = () => {
  const { status: myStatus, signOut, data: session } = useAuth();
  const loggedIn = myStatus.value === "authenticated";

  return computed(() => {
    return {
      loggedIn: loggedIn,
      session: session,
      user: session.value?.user,
      players: session.value?.user?.players,
      signOut: signOut,
      isAdmin: session.value ? session?.value?.user?.isAdmin : false,
    };
  });
};
